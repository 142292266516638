import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBaseURL } from "../../baseURL";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import MuiAlert from '@mui/material/Alert';
import { useHistory } from "react-router-dom";
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import FormGroup from '@mui/material/FormGroup';
import TableHead from '@mui/material/TableHead';
import { green, red} from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getPdfFiles, ViewPdfFiles } from '../../actions/actions';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import { Container, TextField, Button, TablePagination, Snackbar } from "@mui/material";
import clsx from  'clsx';
import axios from "axios";

export const DrawingSearch = () => {
  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(2, 2)
    },
    paperSpacing: {
      paddingTop: "30px"
    },
    wrapper: {
      position: 'relative',
      marginLeft: '10px'
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700]
      }
    },
    buttonError: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700]
      }
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12
    },
    paperSpacing: {
      paddingTop: "30px"
    },
    helperText: {
      marginLeft: 0
    },
    buttonProgressDownload: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12
    },
    buttonProgressAccessRequest: {
      color: '#078b11',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: 190,
      marginLeft: 327
    },
    paragraphText: {
      textAlign: "justify",
      padding: "20px 10px 20px 10px",
      fontSize: "16px"
    },
    spanText: {
      color: '#140a9a'
    },
    tableRowWhiteColor: {
      color: "#fff"
    },
    tableRowGreyColor: {
      background: "#adadad"
    },
    errorStyle: {
      display: "block",
      paddingTop: 10
    },
    gridHeading: {
      fontWeight: 550
    }
  }));

  const classes = useStyles();
  const[page, setPage] = React.useState(0);
  const[latest, setLatest] = useState(true);
  const [exactResults, setExactResults] = useState(false);
  const[drawings, setDrawings] = useState([]);
  const[loading, setLoading] = useState(false);
  const[searchText, setSearchText] = useState("");
  const [AlertColor, setAlertColor] = useState("");
  const[rowsPerPage, setRowsPerPage] = useState(5);
  const currentUser = useSelector(state => state.auth);
  const [SnackBarText, setSnackBarText] = useState("");
  const [SnackBarOpen, setSnackBarOpen] = useState(false);
  const[drawingError, setDrawingError] = useState({Error: false, Text: ""});
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, drawings.length - page * rowsPerPage);
  const [outsideDrawingsError, setOutsideDrawingsError] = useState({ error: false, text: "" });
  const [drawingNotExistsError, setDrawingNotExistsError] = useState({ error: false, text: "" });
  const [useBetaSearch, setUseBetaSearch] = useState(false);
  const [isBeatSearchVisible, setIsBeatSearchVisible] = useState(false);
  const [error, setError] = useState({ Error: false, Text: "" });

  const config = {
    headers: {'Authorization': "Bearer " + currentUser.jwtIdToken.idToken}
  };

  let history = useHistory();

  const onSearchTextChange = event => {
    setSearchText(event.target.value);

    if(searchText) {
      setDrawingError({Error: false, Text: ""});
    }
  }

  const onLatestChanged = event => {
    setLatest(event.target.checked);
  }

  const onExactSearch = event => {
    setExactResults(event.target.checked);
  }

  const handleBetaSearch = event => {
    setUseBetaSearch(event.target.checked);
    // if (event.target.checked === true) {
    //   document.getElementById("getExactResults").disabled = true
    //   document.getElementById("getLatestChanged").disabled = true
    //   if (latest) {
    //     setLatest(!latest);
    //   }
    //   if (exactResults) {
    //     setExactResults(!exactResults);
    //   }
    // } else {
    //   document.getElementById("getExactResults").disabled = false
    //   document.getElementById("getLatestChanged").disabled = false
    // }
  }

  const onSubmit = () => {
    setDrawings([]);
    if(searchText) {
      setOutsideDrawingsError({Error: false, Text: ""});
      setDrawingNotExistsError({Error: false, Text: ""});
      const search = {
        SearchString: searchText.trim(),
        LatestRev: latest,
        useBetaSearch: useBetaSearch,
        ExactResults:exactResults
      }
      setLoading(true);

      Axios.post(`${getBaseURL()}/documents/documentsearch`, search, config)
        .then((response) => {
          if(response.data.length > 0) {
            console.log(currentUser.user.classification);
            let exactMatchDrawings = response.data.filter(x => x.drawingNumber.toLowerCase() == searchText.toLowerCase() || x.description.toLowerCase() == searchText.toLowerCase());

            if(exactMatchDrawings.length == 0){
              setDrawingNotExistsError({Error: true, Text: "The drawing you searched for does not exist in KTIP. Please submit the Scan Request."});
            }
            else{
              setDrawingNotExistsError({Error: false, Text: ""});
              let outsideClassificationLevelDrawings = response.data.filter(x => x.classificationLevel > currentUser.user.classification.classLevel && x.hasSpecialAccess == false);
              if(outsideClassificationLevelDrawings.length > 0){
                setOutsideDrawingsError({Error: true, Text: "This is a restricted access item and you must use the KTIP Special Access request process to view this item."});
              }
              else{
                setOutsideDrawingsError({Error: false, Text: ""});
              }
            }
            //response.data = response.data.filter(x => x.classificationLevel <= currentUser.user.classification.classLevel);
          }
          setDrawings(response.data);
          setLoading(false);
          setPage(0);
          setDrawingError({Error: false, Text: ""});
        })
        .catch((error) => {
          setDrawingNotExistsError({Error: true, Text: "The drawing you searched for does not exist in KTIP. Please submit the Scan Request."});
          setDrawingError({Error: true, Text: error.response.data.message});
          setLoading(false);
          setPage(0);
        })
    }
    else {
      setDrawingError({Error: true, Text: "Enter a drawing number, part number, or part description"});
      setDrawings([]);
    }
  }

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleDownloadClick = (drawingId) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
    setDrawings(drawingsArray);

    var selectedFile = [...drawings].filter(x => x.id === drawingId);
    selectedFile.find(x => x.id === drawingId).isChecked = true;

    getPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
      });
  }

  const handleViewClick = (drawingId) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
    setDrawings(drawingsArray);

    var selectedFile = [...drawings].filter(x => x.id === drawingId);
    selectedFile.find(x => x.id === drawingId).isChecked = true;

    ViewPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
      })
      .catch((error) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
        setAlertColor("error");
        setSnackBarText(error.response.data);
        setSnackBarOpen(true);
      });
  }

  const handleEnter = (event) => {
    if(event.key === 'Enter') {
      onSubmit();
    }
  }

  const handleNullClassificationAccessRequest = (drawingId, drawingNumber) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isAccessRequestSubmitted = true);
    setDrawings(drawingsArray);
    Axios.get(`${getBaseURL()}/requests/SpecialAccessRequestForEmptyClassification/${drawingNumber}`, config)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isAccessRequestSubmitted = false);
        setDrawings(drawingsArray);
        setAlertColor("success");
        setSnackBarOpen(true);
        setSnackBarText("An email has been sent to the administration to access this drawing.");
      })
      .catch((error) => {
        setAlertColor("")
        setSnackBarOpen(true);
        setSnackBarText("Error occured while sending access request email!");
      })
  }

  const getPermissions = () => {
    const User = {
      email: currentUser.user.email
    }
    axios.post(`${getBaseURL()}/admin/manageuserrights`, User, config)
      .then(res => {
        if (res.data.permissions.length > 0) {
          debugger
          const filterName = "Beta Search";
          const filterItem = res.data.permissions.filter(item => item.permissionName.includes(filterName));
          if (filterItem[0].permissionName === filterName) {
            if (filterItem[0].value === true) {
              setIsBeatSearchVisible(true)
            } else {
              setIsBeatSearchVisible(false)
            }
          }
        }
      })
      .catch(error => {
        setError({ Error: true, Text: error.response.data.message });
      });
  }

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <Container maxWidth={false} disableGutters={true}>
      {/* Removed MuiAlert */}
      {/* <MuiAlert severity="warning">If you have been migrated to global.komastu, you'll need to use Adobe Reader to open PDFs from KTIP until everyone has been migrated.</MuiAlert> */}
      <Typography variant="h4" gutterBottom>Drawing Search</Typography>
      <Paper className={classes.root}>
        <TextField
          id="drawingNumber"
          label="Drawing Number, Part Number, or Part Description"
          value={searchText}
          onChange={onSearchTextChange}
          variant="outlined"
          margin="normal"
          fullWidth
          autoFocus
          helperText={drawingError.Error ? drawingError.Text : null}
          error={drawingError.Error}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          onKeyPress={handleEnter}
        />
        <FormGroup row>
          <FormControlLabel control={<Checkbox checked={latest} color="primary" id="getLatestChanged" onChange={onLatestChanged} />} label="Latest Revision Only" />
          <FormControlLabel control={<Checkbox checked={exactResults} color="primary" id="getExactResults" onChange={onExactSearch} />} label="Exact Search Results" />
          {isBeatSearchVisible
            && <FormControlLabel hidden={true} control={<Checkbox hidden={true} checked={useBetaSearch} color="primary" onChange={handleBetaSearch} />} label="Use Beta Search" ></FormControlLabel>
          }
        </FormGroup>
        {/* <FormGroup row>
          <FormControlLabel control={<Checkbox checked={useBetaSearch} color="primary" onChange={() => setUseBetaSearch(!useBetaSearch)} />} label="Use Beta Search" /> 
        </FormGroup> */}

        <span className={classes.wrapper}>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            disabled={loading}
          >
            <SearchIcon />&nbsp;Search
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </span>
        {outsideDrawingsError.Error ? <Typography variant="caption" color="error" className={classes.errorStyle}>{outsideDrawingsError.Text}</Typography> : null}
        {drawingNotExistsError.Error ? <Typography variant="caption" color="error" className={classes.errorStyle}>{drawingNotExistsError.Text}</Typography> : null}
      </Paper>
      <br />
      {(drawings.length > 0 && !loading && !drawingError.Error) ?
        <span>
          <Paper className={classes.paperSpacing} p={5}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.gridHeading}>Drawing Number</TableCell>
                  <TableCell className={classes.gridHeading}>Revision</TableCell>
                  <TableCell className={classes.gridHeading}>Description</TableCell>
                  <TableCell className={classes.gridHeading}>Model</TableCell>
                  <TableCell className={classes.gridHeading}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {drawings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                  return (
                    <TableRow key={item.id} className={item.documentCount == 0 ? classes.tableRowGreyColor : classes.tableRowWhiteColor}>
                      <TableCell>{item.drawingNumber}</TableCell>
                      <TableCell>{item.revision}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.model}</TableCell>
                      {item.classificationLevel != null ?
                        (item.classificationLevel <= currentUser.user.classification.id || item.hasSpecialAccess) ?
                          (item.documentCount && item.documentCount > 0) ?
                            <TableCell>
                              <span className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => handleDownloadClick(item.id)}
                                  disabled={item.isDownloading}
                                >
                                  Download
                                </Button>

                                {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                              </span>
                              {(
                                currentUser.user.permissions.some(p => p.permissionName.toLowerCase() === "view button")
                                &&
                                currentUser.user.permissions.find(p => p.permissionName.toLowerCase() === "view button").value === true
                              ) &&
                                <span className={classes.wrapper}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleViewClick(item.id)}
                                    disabled={item.isDownloading}
                                  >
                                    View
                                  </Button>
                                  {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                                </span>
                              }

                            </TableCell>
                            :
                            <TableCell>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => history.push("/scanrequest/" + item.drawingNumber)}
                              >
                                Scan Request
                              </Button>
                            </TableCell>
                          : <TableCell>
                            <Button color="primary" variant="contained" onClick={() => history.push("/specialaccessrequest/" + item.drawingNumber)}>Request Access</Button>
                          </TableCell>
                        : <TableCell></TableCell>
                      }
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 250, 500]}
              component="div"
              count={drawings.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </span>
        :
        <React.Fragment>
          <Paper>
            <p className={classes.paragraphText}>
              By use of the KTIP application, I verify that I have read, understood, and agree to comply
              with all Komatsu policies related to IP distribution and management including but limited
              to the following:<br />
              <span className={classes.spanText}>Komatsu Company's Worldwide Intellectual Property Policy,
                IT Acceptable Use and Monitoring Policy, Information Security Policy and Intellectual
                Property Policies and Procedures</span> <br /><br />
              Any violation of these may result in disciplinary action, up to and including termination
              of employment and other legal action. Company reserves the right to notify the appropriate
              law enforcement authorities of any unlawful activity and to cooperate in any investigation of
              such activity. Company does not consider conduct in violation of this policy to be within a
              user's course and scope of employment, or the direct consequence of the discharge of the Users
              duties. Accordingly, to the extent permitted by law, Company reserves the right not to defend
              or pay damages awarded against any Users that result from violation of this policy.
            </p>
          </Paper>
          <Paper className={clsx(classes.paperSpacing, classes.root)}>
            <Typography variant="subtitle1">
              One occurrence of 60-day nonuse in a 12-month period – User must reapply
            </Typography>
            <Typography variant="subtitle1">
              Two occurrences of 60-day nonuse in a 12-month period – KTIP administrator will adjust access to special access only
            </Typography>
            <Typography variant="subtitle1">
              Three occurrences of 60-day nonuse in a 12-month period – KTIP administrator will terminate user’s access
            </Typography>
          </Paper>
        </React.Fragment>

      }

      <Snackbar
        open={SnackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={AlertColor === "success" ? "success" : (AlertColor === "info" ? "info" : "error")}>
          {SnackBarText}
        </Alert>
      </Snackbar>
    </Container>
  );
}