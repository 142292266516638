import React, { useState, useEffect } from "react";
import { getBaseURL } from "../../baseURL";
import { PostRequest } from "../../hooks";
import Paper from '@mui/material/Paper';
import { Container, TextField, Button, Checkbox, FormLabel, FormControl, FormGroup, FormControlLabel, MenuItem, Menu, FormHelperText, TablePagination, Snackbar, Grid, Stack, styled, Collapse, Box, IconButton, Alert, Popover, TableContainer } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import { green, red } from '@mui/material/colors';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from "axios";
import ErrorIcon from '@mui/icons-material/Error';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import { getPdfFiles, ViewPdfFiles } from '../../actions/actions';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { TablePaginationActions } from '../../actions/TablePaginationActions';
import { CheckBox, Close, ClosedCaption, Visibility } from "@mui/icons-material";
import moment from "moment/moment";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/Download'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,

}));

// const Alert = React.forwardRef < HTMLDivElement > (function Alert(
//   props,
//   ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// })


export const SpecialAccessRequest = (props) => {

  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(3, 2),
    },
    rootAlt: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    paperSpacing: {
      paddingTop: "30px",
    },
    formControl: {
      minWidth: 150,
    },
    bottomPadding: {
      paddingBottom: "10px",
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
    buttonError: {
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700]
      }
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }));

  const classes = useStyles();

  const currentUser = useSelector(state => state.auth);
  const config = {
    headers: { 'Authorization': "Bearer " + currentUser.jwtIdToken.idToken }
  };

  const [open, setOpen] = React.useState(false);
  const [requestedDocuments, setRequestedDocuments] = useState([]);
  const [requestedDocumentsError, setRequestedDocumentsError] = useState({ error: false, text: "" });
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [businessNeed, setBusinessNeed] = useState({ Name: '', AdditionalInfo: '' });
  const [businessNeedError, setBusinessNeedError] = useState({ error: false, text: "" });
  const [businessJustification, setBusinessJustification] = useState("");
  const [businessJustificationError, setBusinessJustificationError] = useState({ error: false, text: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [printNeeded, setPrintNeeded] = useState(false);
  const [businessNeedText, setBusinessNeedText] = useState("");
  const [businessNeedTextError, setBusinessNeedTextError] = useState({ error: false, text: "" });  
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [partDescription, setpartDescription] = useState("");
  const [partDescriptionError, setpartDescriptionError] = useState({ Error: false, Text: "" });
  const [page, setPage] = React.useState(0);
  const [latest, setLatest] = useState(true);
  const [exactResults, setExactResults] = useState(false);
  const [drawings, setDrawings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [AlertColor, setAlertColor] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [SnackBarText, setSnackBarText] = useState("");
  const [SnackBarOpen, setSnackBarOpen] = useState(false);
  const [drawingError, setDrawingError] = useState({ Error: false, Text: "" });
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, drawings.length - page * rowsPerPage);
  const [outsideDrawingsError, setOutsideDrawingsError] = useState({ error: false, text: "" });
  const [drawingNotExistsError, setDrawingNotExistsError] = useState({ error: false, text: "" });
  const [useBetaSearch, setUseBetaSearch] = useState(false);
  const [isPrintCheck, setIsPrintChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showLess, setshowLess] = useState(true);

  let { documentID } = useParams();

  const timer = React.useRef();


  const onSearchTextChange = event => {
    setSearchText(event.target.value);
    if (searchText) {
      setDrawingError({ Error: false, Text: "" });
    }
  }
  /*-------------------------------------*/


  // const OnAddDrawing = () => {

  // }
  const onSearchSubmit = () => {
    setDrawings([]);
    if (searchText) {
      setOutsideDrawingsError({ Error: false, Text: "" });
      setDrawingNotExistsError({ Error: false, Text: "" });
      const search = {
        SearchString: searchText.trim(),
        LatestRev: latest,
        useBetaSearch: useBetaSearch,
        ExactResults: exactResults,
      }
      setLoading(true);

      Axios.post(`${getBaseURL()}/documents/documentsearch`, search, config)
        .then((response) => {
          if (response.data.length > 0) {
            console.log(currentUser.user.classification);
            let exactMatchDrawings = response.data.filter(x => x.drawingNumber.toLowerCase() == searchText.toLowerCase() || x.description.toLowerCase() == searchText.toLowerCase());

            if (exactMatchDrawings.length == 0) {
              setDrawingNotExistsError({ Error: true, Text: "The drawing you searched for does not exist in KTIP. Please submit the Scan Request." });
            }
            else {
              setDrawingNotExistsError({ Error: false, Text: "" });
              let outsideClassificationLevelDrawings = response.data.filter(x => x.classificationLevel > currentUser.user.classification.classLevel && x.hasSpecialAccess == false);
              if (outsideClassificationLevelDrawings.length > 0) {
                setOutsideDrawingsError({ Error: true, Text: "This is a restricted access item and you must use the KTIP Special Access request process to view this item." });
              }
              else {
                setOutsideDrawingsError({ Error: false, Text: "" });
              }
            }
            //response.data = response.data.filter(x => x.classificationLevel <= currentUser.user.classification.classLevel);
          }
          setDrawings(response.data);
          setLoading(false);
          setPage(0);
          setDrawingError({ Error: false, Text: "" });
        })
        .catch((error) => {
          setDrawingNotExistsError({ Error: true, Text: "The drawing you searched for does not exist in KTIP. Please submit the Scan Request." });
          setDrawingError({ Error: true, Text: error.response.data.message });
          setLoading(false);
          setPage(0);
        })
    }
    else {
      setDrawingError({ Error: true, Text: "Enter a drawing number, part number, or part description" });
      setDrawings([]);
    }
  }

  // const Alert = (props) => {
  //   return <MuiAlert elevation={6} variant="filled" {...props} />;
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleDownloadClick = (drawingId) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
    setDrawings(drawingsArray);

    var selectedFile = [...drawings].filter(x => x.id === drawingId);
    selectedFile.find(x => x.id === drawingId).isChecked = true;

    getPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
      });
  }

  const handleViewClick = (drawingId) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = true);
    setDrawings(drawingsArray);

    var selectedFile = [...drawings].filter(x => x.id === drawingId);
    selectedFile.find(x => x.id === drawingId).isChecked = true;

    ViewPdfFiles(selectedFile, currentUser.jwtIdToken.idToken)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
      })
      .catch((error) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isChecked = false);
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isDownloading = false);
        setDrawings(drawingsArray);
        setAlertColor("error");
        setSnackBarText(error.response.data);
        setSnackBarOpen(true);
      });
  }

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      onSearchSubmit();
    }
  }

  const handleNullClassificationAccessRequest = (drawingId, drawingNumber) => {
    var drawingsArray = [...drawings];
    drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isAccessRequestSubmitted = true);
    setDrawings(drawingsArray);
    Axios.get(`${getBaseURL()}/requests/SpecialAccessRequestForEmptyClassification/${drawingNumber}`, config)
      .then((response) => {
        drawingsArray = [...drawings];
        drawingsArray.filter(x => x.id === drawingId).forEach(x => x.isAccessRequestSubmitted = false);
        setDrawings(drawingsArray);
        setAlertColor("success");
        setSnackBarOpen(true);
        setSnackBarText("An email has been sent to the administration to access this drawing.");
      })
      .catch((error) => {
        setAlertColor("")
        setSnackBarOpen(true);
        setSnackBarText("Error occured while sending access request email!");
      })
  }

  /*-------------------------------------*/
  const buttonClassname = clsx({
    [classes.buttonSuccess]: saved,
    [classes.buttonError]: saveError,
  });

  const businessNeeds = [
    { Name: "Service", AdditionalInfo: "SAP Order Number" },
    { Name: "Sub-Contract", AdditionalInfo: "SAP Order Number" },
    { Name: "Rebuild", AdditionalInfo: "SAP Order Number" },
    { Name: "Manufacture", AdditionalInfo: "SAP Order Number" },
    { Name: "Quote", AdditionalInfo: "Customer Name" },
    { Name: "SalesForce/CCR", AdditionalInfo: "Case/CCR Number" }

  ];

  useEffect(() => {
    // if(props.match.params.documentID && requestedDocuments.length < 1 && !submitted) {
    //   setIsLoading(true);
    //   const formData = {
    //     ID: props.match.params.documentID,
    //   }
    //   PostRequest("api/GetDrawingDetails", formData).then(o => {setRequestedDocuments(o.DrawingNumber); setIsLoading(false);});
    // }

    if (!requestedDocuments && documentID) {
      setRequestedDocuments(documentID);
    }
  }, []);

  const onDrawingInputChange = event => {
    setRequestedDocuments(event.target.value);
    setSaved(false);
    if (event.target.value.length > 0) {
      setRequestedDocumentsError({ error: false, text: "" });
    }
  }

  const onPrintNeededChange = event => {
    setPrintNeeded(event.target.checked);
    setSaved(false);
  }

  const onEffectiveDateChange = effectiveDate => {
    setEffectiveDate(effectiveDate);
    setSaved(false);
  }
  

  const onReasonChange = event => {
    setBusinessNeed({
      Name: event.target.value,
      AdditionalInfo: businessNeeds.find(n => n.Name === event.target.value).AdditionalInfo

    });

    setSaved(false);

    if (event.target.value.length > 0) {
      setBusinessNeedError({ error: false, text: "" });
      setBusinessNeedTextError({ error: false, text: "" });
    }
  };

  const onBusinessNeedTextChange = event => {
    setBusinessNeedText(event.target.value);

    setSaved(false);

    if (event.target.value.length > 0) {
      setBusinessNeedTextError({ error: false, text: "" });
    }
  }


 

  const onPartDescriptiontextChange = event => {
    setpartDescription(event.target.value)

    if (event.target.value.length > 0) {
      setpartDescriptionError({ error: false, text: "" });
    }
  }
  const onBusinessJustificationChange = event => {
    setBusinessJustification(event.target.value);

    setSaved(false);

    if (event.target.value.length > 0) {
      setBusinessJustificationError({ error: false, text: "" });
    }
  }

  const clearForm = () => {
    setRequestedDocuments("");
    setBusinessNeed({ Name: "", AdditionalInfo: "" });
    setBusinessNeedText("");
    setBusinessJustification("");
    setpartDescription("");
   
  }
  const onLatestChanged = event => {
    setLatest(event.target.checked);
  }

  const onExactSearch = event => {
    setExactResults(event.target.checked);
  }

  // Added
  const handleChangePartNo = (e) => {
    if (businessNeed.Name.length < 1) {
      setBusinessNeedError({ error: true, text: "Select Business Reason" });
      isError = true;
    }
    else {
      if (businessNeedText.length < 1) {
        setBusinessNeedTextError({ error: true, text: "Enter " + businessNeed.AdditionalInfo });
        isError = true;
      }
    }
    if (businessJustification.length < 1) {
      setBusinessJustificationError({ error: true, text: "Enter Business Justification" });
      isError = true;
    }

    let effDate = JSON.stringify(effectiveDate);
    effDate = JSON.parse(effDate);
    effDate = moment(effDate).format('MM/DD/YYYY');
    let reqDocument = [
      {
        "id": e.id,
        "RequestedDocuments": e.drawingNumber,
        "BusinessNeedJustification": businessJustification,
        "PrintRequested": printNeeded,
        "EffectiveDate": effectiveDate,
        "BusinessNeed": businessNeed.Name,
        "BusinessNeedDescription": businessNeedText,
        "ClassLevel" :  e.classLevel,
        "PartDescription": e.description,
        "EffectiveDt": effDate,
        "Revision": e.revision
      }
    ];
    let cnt = selected.filter(x => x.id === e.id);
    if (cnt.length > 0) {
      setOpen(true);
    } else {
      reqDocument = reqDocument.concat(selected);
      setSelected(reqDocument);
    }
  }
  const handleRemoveDrawingNumber = (index) => {
    var drawingsArray = [...selected];
    drawingsArray.splice(index, 1);
    setSelected(drawingsArray);
  };
  // Ended

  const onSubmit = () => {
    var isError = false;
    if (businessNeed.Name.length < 1) {
      setBusinessNeedError({ error: true, text: "Select Business Reason" });
      isError = true;
    }
    else {
      if (businessNeedText.length < 1) {
        setBusinessNeedTextError({ error: true, text: "Enter " + businessNeed.AdditionalInfo });
        isError = true;
      }
    }

    // if (requestedDocuments.length < 1) {
    //   setRequestedDocumentsError({ error: true, text: "Enter Document Number(s)" });
    //   isError = true;
    // }

    if (businessJustification.length < 1) {
      setBusinessJustificationError({ error: true, text: "Enter Business Justification" });
      isError = true;
    }
    // if (partDescription.length < 1) {
    //   setpartDescriptionError({ error: true, text: "Enter Part description" });
    //   isError = true;
    // }

    if (!saving && !isError) {
      setSaveError(false);
      setSaved(false);
      setSaving(true);
      console.log(selected);
      try {
        const res = selected.forEach(spl => {
          Axios.post(`${getBaseURL()}/requests/specialaccessrequest/new`, spl, config);
        });
        if (!res) {
          setSaved(true);
          setSaving(false);
          clearForm();
          setSubmitted(true);
          setSelected([]);
          setDrawings([]);
          setSearchText('');
          setPrintNeeded(false);
        }
      } catch (error) {
        setRequestedDocumentsError({ error: true, text: error.response.data.message });
        setSaveError(true);
        setSaving(false);
      }
      // .then(res => {
      //   setSaved(true);
      //   setSaving(false);
      //   clearForm();
      //   setSubmitted(true);
      // })
      // .catch(error => {
      //   setRequestedDocumentsError({ error: true, text: error.response.data.message });
      //   setSaveError(true);
      //   setSaving(false);
      // })
    }
  }

  //PopOver Event
  const [anchorEl, setanchorEl] = React.useState(null);
  const handleClick = (event) => {
    var drawingsArray = [...selected];
    let filteredView = drawingsArray.filter(x => x.id === event);
    let bussinessJustification = filteredView[0].BusinessNeedJustification;
    setanchorEl(bussinessJustification);
  };
  const handleClose = () => {
    setanchorEl(null);
  };
  const open1 = Boolean(anchorEl);
  const id = open1 ? 'simple-popover' : undefined;

  const handleBetaSearch = event => {
    setUseBetaSearch(event.target.checked);
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth={false} disableGutters={true}>
        <Typography variant="h4" gutterBottom>Special Access Request</Typography>
        {/*Start For Search Event */}
        <Paper className={classes.root}>
          <TextField
            id="drawingNumber"
            label="Drawing Number, Part Number, or Part Description"
            value={searchText}
            onChange={onSearchTextChange}
            variant="outlined"
            margin="normal"
            fullWidth
            autoFocus
            helperText={drawingError.Error ? drawingError.Text : null}
            error={drawingError.Error}
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
            onKeyPress={handleEnter}
          />
          <FormGroup row>
            <FormControlLabel control={<Checkbox checked={latest} color="primary" id="getLatestChanged" onChange={onLatestChanged} />} label="Latest Revision Only" />
            <FormControlLabel control={<Checkbox checked={exactResults} color="primary" id="getExactResults" onChange={onExactSearch} />} label="Exact Search Results" />
            {/* <FormControlLabel control={<Checkbox checked={useBetaSearch} color="primary" onChange={handleBetaSearch} />} label="Use Beta Search" ></FormControlLabel> */}
          </FormGroup>
          <span className={classes.wrapper}>
            <Button
              color="primary"
              variant="contained"
              onClick={onSearchSubmit}
              disabled={loading}
            >
              <SearchIcon />&nbsp;Search
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </span>
          {outsideDrawingsError.Error ? <Typography variant="caption" color="error" className={classes.errorStyle}>{outsideDrawingsError.Text}</Typography> : null}
          {drawingNotExistsError.Error ? <Typography variant="caption" color="error" className={classes.errorStyle}>{drawingNotExistsError.Text}</Typography> : null}
        </Paper>
        {/*End For Search Event */}

        {/* Design Start */}
        <Paper className={`${classes.root} ${'MuiPaper-elevation4'}`}>
          <Typography variant="subtitle1">View only access will have a duration of 5 days, and print access will have a duration of 3 days</Typography>
          <Grid Container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Stack direction="row" spacing={2}>
              <Grid >
                <DatePicker
                  label="Effective Date"
                  value={effectiveDate}
                  onChange={(newValue) => onEffectiveDateChange(newValue)}
                  renderInput={(params) => <TextField {...params} variant="standard" />}
                />
              </Grid>
              <Grid item md={4}>
                <FormControl required className={classes.formControl} error={businessNeedError.error}>
                  <InputLabel htmlFor="businessNeed">Business Need</InputLabel>
                  <Select
                    value={businessNeed.Name}
                    variant="standard"
                    onChange={onReasonChange}
                    label="Business Need"
                    required
                    style={{ width: '231px' }}
                  >
                    {businessNeeds.map((need, i) =>
                      <MenuItem key={i} value={need.Name}>{need.Name}</MenuItem>
                    )}
                  </Select>
                  {businessNeedError.error ? <FormHelperText>{businessNeedError.text}</FormHelperText> : null}
                </FormControl>

                &nbsp;
                &nbsp;
                {(typeof (businessNeed.AdditionalInfo) !== 'undefined' && businessNeed.AdditionalInfo.length > 0) ?
                  <TextField
                    id="businessNeedText"
                    label={businessNeed.AdditionalInfo}
                    value={businessNeedText}
                    onChange={onBusinessNeedTextChange}
                    required
                    error={businessNeedTextError.error}
                    helperText={businessNeedTextError.error ? businessNeedTextError.text : null}
                  />
                  : null}


              </Grid>
              <FormControlLabel
                control={
                  <Checkbox checked={printNeeded} onChange={onPrintNeededChange} color="primary" />
                }
                label="Print Access Needed?"
              />
            </Stack>
            <br />
            <br></br>
            <Grid item md={4}>
              <TextField
                id="businessJustification"
                inputProps={{ maxLength: 200 }}
                label="Business Justication - Please keep your response under 200 characters"
                multiline
                rows="5"
                variant="outlined"
                fullWidth
                onChange={onBusinessJustificationChange}
                value={businessJustification}
                required
                error={businessJustificationError.error}
                helperText={businessJustificationError.error ? businessJustificationError.text : null}
              />
            </Grid>
          </Grid>
        </Paper>
        {/* Design End */}

        {/*Start  Searched Record  */}
        <span>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.gridHeading}>Drawing Number</TableCell>
                  <TableCell className={classes.gridHeading}>Revision</TableCell>
                  <TableCell className={classes.gridHeading}>Description</TableCell>
                  <TableCell className={classes.gridHeading}>Model</TableCell>
                  <TableCell className={classes.gridHeading}>Class Level</TableCell>
                  {/* <TableCell className={classes.gridHeading}>Add</TableCell> */}
                  <TableCell align="center" style={{ width: 350 }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {drawings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                  // const selectedUser = selected.indexOf(item.drawingNumber) !== -1;
                  // selected={selectedUser}
                  return (
                    <TableRow key={item.id} className={item.documentCount == 0 || item.byteArray == null ? classes.tableRowGreyColor : classes.tableRowWhiteColor}>
                      <TableCell>{item.drawingNumber}</TableCell>
                      <TableCell>{item.revision}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>{item.model}</TableCell>
                      <TableCell>{item.classLevel}</TableCell>
                      {/* <TableCell >
                        <span className={classes.wrapper}>
                          <CheckIcon onClick={() => handleChangePartNo(item)} />
                        </span>
                      </TableCell> */}
                      {item.classificationLevel != null ?
                        (item.classificationLevel <= currentUser.user.classification.id || item.hasSpecialAccess) ?
                          (item.documentCount && item.documentCount > 0) ?
                            <TableCell>
                              <span className={classes.wrapper}>
                                <Button color="primary" variant="contained" onClick={() => handleChangePartNo(item)} >Add</Button>
                              </span>
                              <span className={classes.wrapper}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => handleDownloadClick(item.id)}
                                  disabled={item.isDownloading}>Download
                                </Button>
                                {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                              </span>
                              {(
                                currentUser.user.permissions.some(p => p.permissionName.toLowerCase() === "view button")
                                &&
                                currentUser.user.permissions.find(p => p.permissionName.toLowerCase() === "view button").value === true
                              ) &&
                                <span className={classes.wrapper}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleViewClick(item.id)}
                                    disabled={item.isDownloading}
                                  >
                                    View
                                  </Button>
                                  {item.isDownloading && <CircularProgress size={24} className={classes.buttonProgressDownload} />}
                                </span>
                              }
                            </TableCell>
                            :
                            <TableCell>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => history.push("/scanrequest/" + item.drawingNumber)}
                              >
                                Scan Request
                              </Button>
                            </TableCell>
                          : <TableCell>
                            <span className={classes.wrapper}>
                              <Button color="primary" variant="contained" onClick={() => handleChangePartNo(item)} >Add</Button>
                            </span>
                            <Button color="primary" variant="contained" onClick={() => history.push("/specialaccessrequest/" + item.drawingNumber)}>Request Access</Button>
                          </TableCell>
                        : <TableCell></TableCell>
                      }
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 250, 500]}
              component="div"
              count={drawings.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableContainer>

          <br></br>
          {/* AlertBox */}
          <Box>
            <Collapse in={open}>
              <Alert severity="error" action={
                <IconButton aria-label="close" color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false)
                  }}
                >
                  <Close fontsize="inherit" />
                </IconButton>
              }
                sx={{ mb: 2 }}>
                The Drawing Number is Already Added
              </Alert>
            </Collapse>
          </Box>
          {/*End ALertBox */}
          {/*Start PopOver */}
          <Popover
            id={id}
            open={open1}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            onClose={handleClose}
          >
            <Typography sx={{ p: 2 }}>{anchorEl}</Typography>
          </Popover>
          {/* PopOver */}

          <Paper className={classes.paperSpacing} p={5}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.gridHeading}>Drawing Number</TableCell>
                  <TableCell className={classes.gridHeading}>Revision</TableCell>
                  <TableCell className={classes.gridHeading}>Is Print Access Needed</TableCell>
                  <TableCell className={classes.gridHeading}>Effective date</TableCell>
                  <TableCell className={classes.gridHeading}>Bussiness Need</TableCell>
                  <TableCell className={classes.gridHeading}>Bussiness Justification</TableCell>
                  <TableCell className={classes.gridHeading}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  selected.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell width={20}>
                        {row.RequestedDocuments}
                      </TableCell>
                      <TableCell>{row.Revision}</TableCell>
                      <TableCell width={10}>{row.PrintRequested === true ? "Yes" : "No"}</TableCell>
                      <TableCell >{row.EffectiveDt}</TableCell>
                      <TableCell>{row.BusinessNeed}</TableCell>
                      <TableCell >{
                        showLess ?
                          row.BusinessNeedJustification.slice(0, 30) :
                          row.BusinessNeedJustification}
                        <VisibilityIcon aria-describedby={id} variant="contained" onClick={() => handleClick(row.id)} />
                      </TableCell>
                      <TableCell>
                        <DeleteIcon onClick={() => handleRemoveDrawingNumber(index)} />
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Paper>
        </span>
        {/*End  Searched Record  */}
        <Paper className={`${classes.root} ${'MuiPaper-elevation4'}`}>
          <div className={classes.rootAlt}>
            <div className={classes.wrapper}>
              <Button
                onClick={onSubmit}
                variant="contained"
                color="primary"
                disabled={saving}
                className={buttonClassname}
              >
                {saveError ? <ErrorIcon /> : null}
                {saved && !saveError && !saving ? <CheckIcon /> : null}
                {!saved && !saving && !saveError ? <SaveIcon /> : null}
                {saving ? <SaveIcon /> : null}
                &nbsp;Submit
              </Button>
              {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </div>
        </Paper>
      </Container>
    </LocalizationProvider>
  );
}
